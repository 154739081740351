<template>
  <div class="row h-100">
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <div class="col-md-12 my-auto">
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="col-md-6 ">
            <div class="auth-wrapper">
              <div class="auth-content mb-0">

                <div class="card-body">
                  <div class="card-title text-center mb-5 font-18">
                    <div class="text-center subtitle">
                      <img src="/static/images/login/logo.png" alt="logo" class="logo">
                      <br/>
                      <span>{{ $t('login.title1') }}</span> {{ $t('login.title2') }}
                    </div>
                  </div>

                  <div class="text-center mb-3 font-18">
                    {{ $t('reset-password.title') }}
                  </div>
                  <form method="post" @submit.prevent="onSubmit('frmPasswordReset')" data-vv-scope="frmPasswordReset">
                    <div class="mb-4">
                      <custom-input
                        v-model="password.new"
                        name="password.new"
                        placeholder="Nova Senha"
                        type="password"
                        rootClassName="md-form mb-0"
                        inputClassName="md-form-control"
                        v-validate="{ required: true }"
                        :error="(submitted) ? errors.first('password.new') : ''">
                      </custom-input>
                    </div>
                    <div class="mb-4">
                      <custom-input
                        v-model="password.repeat"
                        name="password.repeat"
                        placeholder="Confirmar Senha"
                        type="password"
                        rootClassName="md-form mb-0"
                        inputClassName="md-form-control"
                        v-validate="{ required: true, confirmed: password.new}"
                        :error="(submitted) ? errors.first('password.repeat') : ''">
                      </custom-input>
                    </div>

                    <div class="mb-5 text-center">
                      <button class="btn btn-primary btn-padding" type="submit">{{ $t('reset-password.submit') }}</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
// import router from '@/router'
import { loginService } from '@/services/loginService'
import CustomInput from '@/components/Forms/CustomInput.vue'
import VeeValidate from 'vee-validate'
import { locale } from '@/utils/validator'

// Loading
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import './login.css'
import '@/assets/css/custom.css'
import BaseService from '@/services/BaseService'
import AddressService from '@/services/AddressService'

VeeValidate.Validator.localize({ 'pt_BR': locale })
Vue.use(VeeValidate, { locale: 'pt_BR' })

export default {
  name: 'PasswordReset',
  metaInfo: {
    titleTemplate: '%s | Alterar senha'
  },
  components: {
    CustomInput,
    Loading
  },
  data () {
    return {
      submitted: null,
      token: null,
      password: {
        new: null,
        repeat: null
      },
      isLoading: false,
      fullPage: true,
      BaseURL: process.env.VUE_APP_BaseURL,
      passwordResetRequest: {
        username: null
      }
    }
  },
  created () {
    let _this = this
    _this.isLoading = true

    _this.token = _this.$route.params.token

    BaseService.checkPasswordChangeAccess(_this.token).then(res => {
      if (res.data.code === 201 && res.data.message === 'Sucesso') {
        // Load basilares data
        AddressService.getUFs({ country: 22, per_page: 50 }).then(res => {
          _this.stateList = res.data.data
        })
      }
    }).catch(() => {
      this.error = true
      global.instanceApp.$alertSwal.modalAlert('', '<div>Link inválido ou expirado, por favor <br/> solicite a recuperacação de senha <br/> novamente.</div>', 'warning').then((result) => {
        global.instanceApp.$router.push({ name: 'Login' })
      })
    }).finally(() => {
      _this.isLoading = false
    })
  },
  methods: {
    addValidate (field) {
      this.$validator.attach(field.fieldId, field.fieldRules)
    },
    onSubmit (scopeName) {
      this.submitted = true
      let _this = this
      this.$validator.validateAll(scopeName).then((result) => {
        if (result) {
          _this.isLoading = true
          loginService.passwordReset(_this.token, _this.password.new)
            .then(function (res) {
              if (res) {
                _this.$router.push({ name: 'Login' })
              } else {
                _this.$router.push({ name: 'PasswordResetRequest' })
              }
            })
            .finally(() => {
              _this.isLoading = false
            })
        }
      })
    }
  }
}
</script>

<style scoped>
  .logo-wrapper span {
    font-size: 6vw;
    color: #84754E;
  }

  .card-title span {
    font-weight: 600;
    font-size: 16px;
  }

  .card-title .logo {
    display: block;
    margin: 0 auto 20px;
  }
</style>
